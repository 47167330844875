<template>
  <div>
    <img :src="images.head" />
    <div class="mb-5">
      <div class="line"></div>
      <h1 class="mt-5 mb-3">Online Shopping Here</h1>
      <Icons />
      <h2>
        <a class="shopee" href="https://shopee.co.th/lannaproducts"
          >>> Shopee &lt;&lt;</a
        >
      </h2>
      <div class="line mt-3"></div>
    </div>
    <h1>E-Catalog Service</h1>
    <p>
      <a href="https://online.fliphtml5.com/twait/lbts/#p=1">
        &gt;&gt; Click Here &lt;&lt;</a
      >
    </p>
    <img :src="images.catalog" alt="QR Code" />
  </div>
</template>

<script>
import { storage } from "../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
import Icons from "@/components/Icons.vue";

export default {
  components: { Icons },
  data() {
    return {
      images: {
        head: "",
        catalog: "",
      },
    };
  },
  created() {
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(storage, `shopping/${key}.png`)).then((url) => {
        this.images[key] = url;
      })
    );
  },
};
</script>

<style scoped>
div {
  color: #2a3e47;
}

img {
  width: 90%;
  margin-top: 40px;
  margin-bottom: 50px;
}

.line {
  width: 250px;
  border-bottom: #2a3e47 solid 7px;
  text-align: center;
  display: inline-block;
}

.shopee {
  color: rgb(255, 102, 0);
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  img {
    width: 100%;
    margin-top: 15px;
  }
}
</style>
