<template>
  <b-container class="mt-4 mb-4">
    <b-row>
      <b-col
        ><a href="https://www.facebook.com/lpc.lannaproducts/"
          ><img :src="images.fb" alt="Facebook Icon" /></a
      ></b-col>
      <b-col
        ><a href="https://lin.ee/ZXACLmc"
          ><img :src="images.line" alt="Line Icon" /></a
      ></b-col>
      <b-col
        ><a href="http://m.me/lpc.lannaproducts"
          ><img :src="images.msg" alt="Messenger Icon" /></a
      ></b-col>
    </b-row>
  </b-container>
</template>

<script>
import { storage } from "../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
export default {
  data() {
    return {
      images: {
        line: "",
        fb: "",
        msg: "",
      },
    };
  },
  created() {
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(storage, `shopping/${key}.png`)).then((url) => {
        this.images[key] = url;
      })
    );
  },
};
</script>

<style scoped>
img {
  width: 80%;
  /* width: 70px; */
}
@media screen and (min-width: 1000px) {
  img {
    width: 130px;
  }
  .row {
    width: 60%;
    margin: auto;
  }
}
</style>
